import { FC, createElement, Fragment } from 'react';
import { createRoot, Root } from 'react-dom/client';

export abstract class ReactCustomElement<P extends {}> extends HTMLElement {
  private _validationTimerId = 0;

  /**
   * We can't use isConnected from the Node class (https://developer.mozilla.org/en-US/docs/Web/API/Node/isConnected)
   * The custom element polyfill we use for Edge support does not set it (ever).
   *
   * FIXME: once we drop non-Chromium Edge support, we can use isConnected instead.
   */
  private _connected = false;
  private _root: Root | undefined;

  protected _getConnected() {
    return this._connected;
  }

  //
  // The FunctionComponent used to render the element.
  //
  protected abstract _getRenderer(): FC<P> | undefined;

  //
  // Returns the properties to apply to the renderer, or null
  // if the renderer's properties can not be satisfied.
  //
  protected abstract getRenderProps(): P | null;

  protected _invalidateProps() {
    if (this._connected && this._validationTimerId === 0) {
      this._validationTimerId = window.setTimeout(this._render, 0);
    }
  }

  private _render = () => {
    const renderer = this._getRenderer();
    if (!renderer) {
      if (this._root) {
        this._root.render(createElement(Fragment));
      }
      return;
    }

    const props = this._root && this.getRenderProps();
    // Block invalidation on getRenderProps
    clearTimeout(this._validationTimerId);
    this._validationTimerId = 0;

    if (props) {
      this._root!.render(createElement(renderer, props));
    } else if (this._root) {
      this._root.render(createElement(Fragment));
    }
  };

  connectedCallback() {
    this._connected = true;
    if (!this._root) {
      this._root = createRoot(this);
    }
    this._render();
  }

  disconnectedCallback() {
    this._connected = false;
    if (this._root) {
      this._root.unmount();
      this._root = undefined;
    }
    this._render();
  }

  //
  // This callback gets called anytime an observed attribute is changed.
  //
  attributeChangedCallback(_name: string, _old: string | null, _value: string | null) {
    this._invalidateProps();
  }

  //
  // Subclasses should override to return their own attribute list.
  // That list should include the observed attributes of the super class.
  //
  static get observedAttributes(): string[] {
    return [];
  }
}
