import { AbstractReportElement } from './AbstractReportElement';
import { SASReportPageProps } from '../components/SASReportPage';
import { getImports } from './dynamicImports';

export class SASReportPageElement extends AbstractReportElement<SASReportPageProps> {
  protected _getRenderer() {
    return getImports()?.renderers.SASReportPage;
  }

  static get observedAttributes() {
    return [...super.observedAttributes, 'pagename', 'pageindex', 'reportcontextkey'];
  }

  protected _getContextKey(props: SASReportPageProps) {
    // Default for page is to use _elementKey for context
    if (!props.reportContextKey) {
      return super._getContextKey(props);
    }
    return 'url' in props
      ? `${props.url}:${props.reportUri}:${props.reportContextKey}`
      : `${props.packageUri}:${props.reportContextKey}`;
  }

  protected getRenderProps(): SASReportPageProps | null {
    const commonProps = this.getCommonProps();
    if (!commonProps) {
      return null;
    }
    const index = this.pageIndex;
    if (this.pageName) {
      return {
        ...commonProps,
        pageName: this.pageName,
        reportContextKey: this.reportContextKey,
      };
    } else if (index !== null) {
      return {
        ...commonProps,
        pageIndex: index,
        reportContextKey: this.reportContextKey,
      };
    }
    return null;
  }

  get pageName() {
    return this.getAttribute('pageName');
  }

  set pageName(value) {
    if (value && typeof value === 'string') {
      this.setAttribute('pageName', value);
    } else {
      this.removeAttribute('pageName');
    }
  }

  get pageIndex(): number | null {
    const attr = this.getAttribute('pageIndex');
    return normalizeNumericInput(attr);
  }

  set pageIndex(value) {
    // @ts-ignore
    const num = normalizeNumericInput(value);
    if (num !== null) {
      this.setAttribute('pageIndex', num.toString());
    } else {
      this.removeAttribute('pageIndex');
    }
  }

  get reportContextKey() {
    return this.getAttribute('reportContextKey') || undefined;
  }

  set reportContextKey(value) {
    if (value && typeof value === 'string') {
      this.setAttribute('reportContextKey', value);
    } else {
      this.removeAttribute('reportContextKey');
    }
  }
}

function normalizeNumericInput(value: unknown): number | null {
  if (typeof value === 'string' && /^\d+$/.test(value)) {
    return Number(value);
  } else if (typeof value === 'number' && value >= 0 && Number.isSafeInteger(value)) {
    return Math.abs(value); // Math.abs is needed to handle -0
  } else {
    return null;
  }
}
