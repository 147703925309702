import { publicPath } from './fixWebpackPublicPath';
import { loadScript } from '@sas-dvr/ltjs-commons/util/ltjsLoaderUtil';
import { ltjsGlobalNamespace } from '@sas-dvr/ltjs-commons/ltjsInit';
import * as WebElements from './elements';
import { displayImagesForMobile } from './elements/dynamicImports/isMobile';

import '@sas/nova-commons/embedded.css';
import './loader.css';

// Prevent nova-core from making calls to Viya
import { initConfiguration } from '@sas/nova-core/config';
initConfiguration({ disableConfigurationEndPoint: true });
(window as any).sas.axiosPreventFetchAcceptLocale = true;

declare const ROOT_CLASS_NAME: string;

if (displayImagesForMobile()) {
  import('./elements/dynamicImports/mobileImportsImpl').then(({ renderers }) => {
    (window as any).vaReportComponents = {
      ...renderers,
    };
    const event = new CustomEvent('vaReportComponents.loaded');
    dispatchEvent(event);
  });
} else {
  //
  // Load LTJS
  // Set the ltjslibpath relative to this loader script.
  //
  ltjsGlobalNamespace.resourceUrl = publicPath + 'assets/';
  // va-sdk UMD builds exclude ICU assets, so always use browser collation
  ltjsGlobalNamespace.useBrowserCollation = true;
  loadScript([]);

  import('./elements/dynamicImports/dynamicImportsImpl').then(
    ({
      SASReport,
      SASReportObject,
      SASReportPage,
      registerDataDrivenContent,
      connectToServer,
      setUseHighContrastReportTheme,
      setLoadingTheme,
      initRootStyles,
      setLocale,
      setFormatterLocale,
      setHonorLocalFormatSettings,
      setupLtjsEnvironment,
      setupLtjsRuntime,
    }) => {
      setupLtjsEnvironment();
      setupLtjsRuntime();
      initRootStyles(ROOT_CLASS_NAME);

      // TODO: Type validation?
      (window as any).vaReportComponents = {
        //TODO: remove the undocumented react exports?
        // We could shim etc. the other exports and remove the need for vaReportComponents.loaded (but maybe keep it around)
        SASReport,
        SASReportObject,
        SASReportPage,
        ...WebElements,
        registerDataDrivenContent,
        connectToServer,
        setUseHighContrastReportTheme,
        setLoadingTheme,
        setLocale,
        setFormatterLocale,
        setHonorLocalFormatSettings,
      };
      setHonorLocalFormatSettings(true);
      const event = new CustomEvent('vaReportComponents.loaded');
      dispatchEvent(event);
    }
  );
}
